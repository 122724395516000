
import * as THREE from 'three'
import nipplejs from 'nipplejs'
import Draggable from "gsap/Draggable"
import { gsap } from 'gsap'

import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import itemsConfig from './itemsConfig'
import { and, bool, js, min } from 'three/examples/jsm/nodes/Nodes.js'
import { GUI } from 'dat.gui';
import { DebugMenu } from './debugMenu';

const SIZE_IMAGE_PIXEL = 512
const IS_DEBUG_MENU = false;


gsap.registerPlugin(Draggable)

class Editor {
    constructor() {
        THREE.Cache.enabled = true
        this.scene = new THREE.Scene()
        this.clock = new THREE.Clock()
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, })
        this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 2000,)
        this.controls = new OrbitControls(this.camera, this.renderer.domElement)
        this.currentItem = null // ITEM
        this.currentExtras = [] // EXTRA
        this.currentPackages = [] // ITEM
        this.activeItem = null // VISIBLE ITEM / EXTRA / PACKAGE
        this.isTextureUpdating = false
        this.newImageAdded = false
        this.forceUpdate = false
        this.deb = null
        this.deb2 = null
        this.tableSize = { width: 50, height: 100 }
        this.avatar = null
        this.initialCameraPosition = new THREE.Vector3(0, 0, 0)

        if (IS_DEBUG_MENU) {
            this.debugMenu = undefined;
        }

        this.hdriLoader = new RGBELoader();
        this.hdriTexture = null;

        this.rotator = new RotationControl(document.querySelector('.rotation-control'), () => {
            this.isTextureUpdating = true

            clearTimeout(this.deb);
            this.deb = setTimeout(() => {
                this.isTextureUpdating = false
                getCurrentState()
            }, 100)
        })

        this.manager = nipplejs.create({
            zone: document.getElementById('zone_joystick'),
            mode: 'static',
            position: { left: '50%', top: '50%' },
            multitouch: false,
            size: 50,
        })
        this.movingData = {}
        this.scalingData = 0
        this.prints = {}

        window.drawingCanvas = document.getElementById('drawing-canvas')
        window.drawingContext = drawingCanvas.getContext('2d')

        document.addEventListener('DOMContentLoaded', () => {
            const joystick = document.getElementById('scalecontroll');
            new ScaleController(joystick, ((scale) => {
                this.isTextureUpdating = true

                this.scalingData = scale

                clearTimeout(this.deb2);
                this.deb2 = setTimeout(() => {
                    this.isTextureUpdating = false
                    getCurrentState()
                }, 100)
            }));
        });

    }

    setAvatar(avatar) {
        this.avatar = avatar
    }

    applyJSONSettingsLight(jsonSettings) {

        jsonSettings.forEach(settings => {
            let light;
            switch (settings.type) {
                case 'PointLight':
                    light = new THREE.PointLight();
                    break;
                case 'SpotLight':
                    light = new THREE.SpotLight();
                    break;
                case 'DirectionalLight':
                    light = new THREE.DirectionalLight();
                    break;
                case 'AmbientLight':
                    light = new THREE.AmbientLight();
                    break;
                case 'HemisphereLight':
                    light = new THREE.HemisphereLight();
                    break;
                case 'RectAreaLight':
                    light = new THREE.RectAreaLight();
                    break;
            }

            if (light) {
                light.intensity = settings.intensity;
                light.color.setHex(settings.color);
                light.position.set(settings.position.x, settings.position.y, settings.position.z);
                light.userData.position = light.position.clone();
                light.userData.attachLightToCamera = settings.attachLightToCamera == undefined ? false : settings.attachLightToCamera;

                if (light instanceof THREE.SpotLight || light instanceof THREE.PointLight) {
                    light.distance = settings.distance;
                    light.decay = settings.decay;
                }

                if (light instanceof THREE.SpotLight) {
                    light.angle = settings.angle;
                    light.penumbra = settings.penumbra;
                }

                if (light instanceof THREE.DirectionalLight) {
                    light.shadow.camera.top = settings.shadow.top;
                    light.shadow.camera.bottom = settings.shadow.bottom;
                    light.shadow.camera.left = settings.shadow.left;
                    light.shadow.camera.right = settings.shadow.right;
                    light.shadow.camera.near = settings.shadow.near;
                    light.shadow.camera.far = settings.shadow.far;
                    // light.shadow.radius = settings.shadow.radius;
                }
                light.castShadow = settings.castShadow;

                if (light instanceof THREE.HemisphereLight) {
                    light.groundColor.setHex(settings.groundColor);
                }

                this.scene.add(light);
            }
        });
    }

    loadHDRI(path, clearURL = false) {
        return new Promise(async (resolve, reject) => {

            this.hdriLoader.load(path, (texture) => {

                const intensity = 1.0
                const originalIntensity = texture.intensity;

                texture.intensity = originalIntensity * intensity;

                const pmremGenerator = new THREE.PMREMGenerator(this.renderer)


                const envMap = pmremGenerator.fromEquirectangular(texture).texture
                this.scene.environment = envMap;
                this.scene.background = envMap;

                texture.dispose()
                pmremGenerator.dispose()

                if (clearURL == true) {
                    URL.revokeObjectURL(path);
                }

                resolve();
            });
        });
    }

    setActiveItem(item, type) {
        this.activeItem = item
        if (type == 'item') {
            this.currentItem = item
        }
        if (type == 'extra') {
            this.currentExtras.push(item)
        }
        if (type == 'package') {
            this.currentPackages.push(item)
        }
    }

    async setCurrentPrints() {
        return new Promise(async (resolve, reject) => {
            this.prints = {}
            this.prints[this.activeItem.name] = {}

            for (let index = 0; index < this.activeItem.objects.length; index++) {
                const element = this.activeItem.objects[index]

                if (element.params && element.params.prints) {
                    const elementPrints = JSON.parse(JSON.stringify(element.params.prints))
                    if (!this.prints[this.activeItem.name][element.name]) {
                        this.prints[this.activeItem.name][element.name] = {
                            prints: [],
                            updateNeeded: true,
                        }
                    }
                    for (let index = 0; index < elementPrints.length; index++) {
                        const print = elementPrints[index]

                        await this.addPrint(element.name, print.url, print.id, print)
                    }
                } else {
                    this.prints[this.activeItem.name][element.name] = {
                        prints: [],
                        updateNeeded: true,
                    }
                }
            }

            resolve()
        })
    }

    setActivePart(data) {
        this.activePart = data
    }

    updatePrintLayer(part, id, z) {
        const element = this.prints[this.activeItem.name]?.[part]
        const print = element.prints.find((el) => el.id == id)

        print.z = z
        element.updateNeeded = true

        this.prints[this.activeItem.name][part].prints.sort((a, b) => a.z - b.z)

        this.forceUpdate = true
    }

    setActivePrint(data) {
        this.activePrint = data

        try {
            const element = this.prints[this.activeItem.name][this.activePart.name];
            const activePrint = element?.prints?.find(item => item.id === this.activePrint);
            if (activePrint != undefined && activePrint != null
                && activePrint.rotation != undefined && activePrint.rotation != null) {
                this.rotator._currentRotation = activePrint.rotation * -1;
            }
            else {
                this.rotator._currentRotation = 0;
            }

            this.rotator._updateRotation();
            this.rotator._setCircleRotation();
        }
        catch (error) {
            console.error("Error setActivePrint:", error);
        }
    }

    async drawImage(url, where) {
        where.crossOrigin = 'anonymous'
        await new Promise(r => where.onload = r, where.src = url)
    }

    calcMaxScale(part) {

        let partData = itemsConfig[this.activeItem.name].config[part]

        if (partData.size == undefined) {
            return 1;
        }
        let maxDistance = partData.size.width > partData.size.height ? partData.size.width : partData.size.height
        let minSizeTable = this.tableSize.width > this.tableSize.height ? this.tableSize.height : this.tableSize.width

        let scale = this.minSizeTable / maxDistance;

        return scale;
    }

    async addPrint(part, url, id, print) {
        let img = new Image()

        let calcMaxScale = this.calcMaxScale(part);

        await this.drawImage(url, img)

        let printData = {
            url: url,
            id: id,
            img: img,
            maxScale: calcMaxScale,
            pos: (print && print.pos != undefined) ? print.pos : {
                x: 0,
                y: 0
            },
            z: (print && print.z) ? print.z : 0,
            rotation: (print && print.rotation != undefined) ? print.rotation : 1,
            scale: (print && print.scale != undefined) ? Math.max(0.01, Math.min(print.scale, 1)) : 1
        }

        let prints = this.prints[this.activeItem.name]?.[part]?.prints;
        if (prints == undefined) {
            prints = [];
        }

        this.prints[this.activeItem.name][part] = {
            prints: [
                ...prints,
                printData
            ],
            updateNeeded: true
        }

        this.addPrintToPart(part, printData)

        this.newImageAdded = true
    }

    async addPrintToPart(part, printData) {
        const object = this.activeItem.objects.find((el) => el.name == part)

        if (!object.params.prints) {
            object.params.prints = []
        }
        const print = object.params.prints.find((el) => el.id == printData.id)

        if (print) {
            object.params.prints[object.params.prints.indexOf(print)] = printData
        } else {
            object.params.prints.push(printData)
        }
    }

    async deletePrint(part, id) {
        let array = this.prints[this.activeItem.name][part].prints
        const object = this.activeItem.objects.find((el) => el.name == part)

        let index = array.findIndex(item => item.id == id);

        if (this.activePrint == array[index]) {
            this.activePrint = null
        }

        if (index !== -1) {
            array.splice(index, 1);
        }

        object.params.prints = array

        this.prints[this.activeItem.name][part] = {
            prints: array,
            updateNeeded: true
        }

        this.newImageAdded = true
    }

    updateItem(data) {
        this.activeItem.update(data)
    }

    updatePackage(data) {
        this.activeItem.update(data)
    }

    updateScale(scale) {
        this.isTextureUpdating = true

        this.scalingData = scale

        clearTimeout(this.deb2);
        this.deb2 = setTimeout(() => {
            this.isTextureUpdating = false
            getCurrentState()
        }, 100)
    }

    toggleDebugMenu() {
        if (IS_DEBUG_MENU) {
            this.debugMenu.toggleVisibility();
        }
    }

    fetchJSONFile(url) {
        return fetch(url)
            .then(response => {
                if (!response.ok) {
                    return undefined;
                }
                return response.json();
            });
    }

    setCamera(newPos) {
        this.controls.target.set(
            newPos != null ? newPos.x : this.initialCameraPosition.x,
            newPos != null ? newPos.y : this.initialCameraPosition.y,
            newPos != null ? newPos.z : this.initialCameraPosition.z
        )
        this.controls.update()
    }

    rotateItem(deg) {
        this.activeItem.rotate(deg)
        this.avatar.rotate(deg)
    }

    resetCamera() {
        this.camera.position.copy(this.initialCameraPosition)
    }

    getCameraPosition() {
        return this.camera.position
    }

    getInitialCameraPosition() {
        return this.initialCameraPosition;
    }

    init() {
        // container.appendChild(this.stats.dom)

        // window.addEventListener('keydown', (event) => {
        //     if (event.key === 'd' || event.key === 'D') {
        //         this.toggleDebugMenu();
        //     }
        // });


        this.renderer.setPixelRatio(window.devicePixelRatio)
        this.renderer.setSize(window.innerWidth, window.innerHeight)
        container.appendChild(this.renderer.domElement)

        // const pmremGenerator = new THREE.PMREMGenerator(this.renderer)

        this.scene.background = new THREE.Color(0xb000000)
        // scene.environment = pmremGenerator.fromScene(new RoomEnvironment(this.renderer), 0.04).texture

        // const geometry = new THREE.BoxGeometry(1, 1, 1)
        // const material = new THREE.MeshBasicMaterial({ color: 0xff0000 })
        // const mesh = new THREE.Mesh(geometry, material)
        // mesh.position.set(0,0,0)

        // this.scene.add(mesh)

        const ambientLight = new THREE.AmbientLight(0xffffff, 0.26)
        this.scene.add(ambientLight)

        const hemiLight = new THREE.HemisphereLight(0xffffff, 0x000000, 0.26)
        this.scene.add(hemiLight)

        const dirLight = new THREE.DirectionalLight(0xffffff, 0.45)
        dirLight.position.set(5, 10, 7)
        dirLight.castShadow = true
        dirLight.shadow.camera.top = 10
        dirLight.shadow.camera.bottom = -10
        dirLight.shadow.camera.left = -10
        dirLight.shadow.camera.right = 10
        dirLight.shadow.camera.near = 0.1
        dirLight.shadow.camera.far = 100
        this.scene.add(dirLight)

        dirLight.shadow.radius = 8

        this.camera.position.copy(this.initialCameraPosition)
        this.prevPositionCamera = new THREE.Vector3(0, 0, 0);

        this.controls.maxDistance = 2.8
        this.controls.minDistance = 0.6
        this.controls.minPolarAngle = 1.2
        this.controls.maxPolarAngle = 1.6

        this.controls.target.set(0, 1.4, 0)
        this.controls.update()
        this.controls.enablePan = false
        this.controls.enableDamping = false

        // const geometry = new THREE.SphereGeometry(15, 32, 32);
        // const material = new THREE.MeshBasicMaterial({ color: 0x000000 });
        // material.side = THREE.BackSide;
        // const sphere = new THREE.Mesh(geometry, material);

        // sphere.position.copy(new THREE.Vector3(0, 1, 0))

        // this.scene.add(sphere);

        window.onresize = () => this.updateSize()

        this.manager.on('start', (evt, data) => {
            this.movingData = data
            this.isTextureUpdating = true
        })
        this.manager.on('end', (evt, data) => {
            this.movingData = data
            this.isTextureUpdating = false
            getCurrentState()
        })

        this.manager.on('move', (evt, data) => {
            this.movingData = data
        })


        if (IS_DEBUG_MENU) {
            this.debugMenu = new DebugMenu(this.scene, this.renderer, this);
            this.debugMenu.toggleVisibility()
        }

        this.loop()
    }

    showItem() {
        this.avatar?.show()
        if (this.activeItem) this.activeItem.hide()
        this.activeItem = this.currentItem
        if (this.activeItem) this.activeItem.show()
        this.setCamera(this.activeItem.cameraPos)


        if (this.prints[this.activeItem.name] == undefined) {
            this.prints[this.activeItem.name] = {}
        }
    }

    hideItem() {
        if (this.activeItem) this.activeItem.group.visible = false
        this.avatar.hide()
    }

    showExtra(name) {
        this.avatar?.hide()
        if (this.activeItem) this.activeItem.hide()
        if (name) {
            const finded = this.currentExtras.find(el => el.name == name)
            this.activeItem = finded
        } else {
            this.activeItem = this.currentExtras.length > 0 ? this.currentExtras[0] : null
        }
        if (this.activeItem) this.activeItem.show()
        this.setCamera(this.activeItem.cameraPos)

        if (this.prints[this.activeItem.name] == undefined) {
            this.prints[this.activeItem.name] = {}
        }
    }

    hideExtra() {
        if (this.activeItem) this.activeItem.group.visible = false
    }

    showPackage(name) {
        this.avatar?.hide()
        if (this.activeItem) this.activeItem.hide()
        if (name) {
            const finded = this.currentPackages.find(el => el.name == name)
            this.activeItem = finded
        } else {
            this.activeItem = this.currentPackages.length > 0 ? this.currentPackages[0] : null
        }
        if (this.activeItem) this.activeItem.show()
        this.setCamera(this.activeItem.cameraPos)

        if (this.prints[this.activeItem.name] == undefined) {
            this.prints[this.activeItem.name] = {}
        }

    }

    hidePackage() {
        if (this.activeItem) this.activeItem.group.visible = false
    }

    updateSize() {
        this.camera.aspect = window.innerWidth / window.innerHeight
        this.camera.updateProjectionMatrix()

        this.renderer.setSize(window.innerWidth, window.innerHeight)
    }

    sizeTable(width, height) {
        this.tableSize = { width: width, height: height };
    }

    addObject(object) {
        this.scene.add(object)
    }

    compareVector3WithPrecision(vec1, vec2, precision) {
        const factor = Math.pow(10, precision);
        const vec1X = Math.round(vec1.x * factor) / factor;
        const vec2X = Math.round(vec2.x * factor) / factor;

        const vec1Y = Math.round(vec1.y * factor) / factor;
        const vec2Y = Math.round(vec2.y * factor) / factor;

        const vec1Z = Math.round(vec1.z * factor) / factor;
        const vec2Z = Math.round(vec2.z * factor) / factor

        return vec1X === vec2X && vec1Y === vec2Y && vec1Z === vec2Z;
    }


    // updateLightsPosition(isForceUpdate = false) {
    //     try {
    //         // if (!this.compareVector3WithPrecision(this.prevPositionCamera, this.camera.position, 3) || isForceUpdate) 
    //         {

    //             let centerPosition = this.controls.target.clone();
    //             let newCameraPosition = this.camera.position.clone().sub(centerPosition).normalize();
    //             let initialCameraPosition = this.initialCameraPosition.clone().sub(centerPosition).normalize();

    //             let rotationAxis = new THREE.Vector3().crossVectors(initialCameraPosition, newCameraPosition).normalize();
    //             let rotationAngle = Math.acos(initialCameraPosition.dot(newCameraPosition));

    //             let crossProduct = new THREE.Vector3().crossVectors(initialCameraPosition, newCameraPosition);


    //             let rotationQuaternion = new THREE.Quaternion().setFromAxisAngle(rotationAxis, rotationAngle);

    //             this.scene.traverse((object) => {
    //                 try {
    //                     if (object instanceof THREE.Light && object.userData.position !== undefined) {
    //                         if (object.userData.attachLightToCamera === true ) {
    //                             let lightInitialPosition = object.userData.position.clone().sub(centerPosition);

    //                             let rotatedPosition = lightInitialPosition.applyQuaternion(rotationQuaternion);
    //                             let newLightPosition = rotatedPosition.add(centerPosition);

    //                             object.position.copy(newLightPosition);

    //                             lightInitialPosition = null;
    //                             rotatedPosition = null;
    //                             newLightPosition = null;
    //                         } else {
    //                             object.position.copy(object.userData.position);
    //                         }
    //                     }
    //                 } catch (error) {
    //                     console.error("Error traversing object:", error);
    //                 }
    //             });

    //             centerPosition = null;
    //             newCameraPosition = null;
    //             initialCameraPosition = null;
    //             rotationAxis = null ;
    //             rotationAngle = null;
    //             crossProduct = null;
    //             rotationQuaternion = null;


    //             this.prevPositionCamera = this.camera.position.clone();
    //         }
    //     } catch (error) {
    //         console.error("Error updateLightsPosition:", error);
    //     }
    // }        

    updateLightsPosition(isForceUpdate = false) {
        try {
            // if (!this.compareVector3WithPrecision(this.prevPositionCamera, this.camera.position, 3) || isForceUpdate) 
            {
                let centerPosition = this.controls.target.clone();
                let newCameraPosition = this.camera.position.clone().sub(centerPosition);
                let initialCameraPosition = this.initialCameraPosition.clone().sub(centerPosition);

                let initialSpherical = new THREE.Spherical().setFromVector3(initialCameraPosition);
                let newSpherical = new THREE.Spherical().setFromVector3(newCameraPosition);

                let deltaTheta = newSpherical.theta - initialSpherical.theta;
                let deltaPhi = newSpherical.phi - initialSpherical.phi;

                this.scene.traverse((object) => {
                    try {
                        if (object instanceof THREE.Light && object.userData.position !== undefined) {
                            if (object.userData.attachLightToCamera === true) {
                                let lightInitialPosition = object.userData.position.clone().sub(centerPosition);

                                let lightSpherical = new THREE.Spherical().setFromVector3(lightInitialPosition);

                                lightSpherical.theta += deltaTheta;
                                lightSpherical.phi += deltaPhi;

                                let newLightPosition = new THREE.Vector3().setFromSpherical(lightSpherical);
                                newLightPosition.add(centerPosition);

                                object.position.copy(newLightPosition);
                                // object.position.copy(this.camera.position);


                            } else {
                                object.position.copy(object.userData.position);
                            }
                        }
                    } catch (error) {
                        console.error("Error traversing object:", error);
                    }
                });

                this.prevPositionCamera = this.camera.position.clone();
            }
        } catch (error) {
            console.error("Error updateLightsPosition:", error);
        }
    }

    loop() {
        const clock = this.clock
        const controls = this.controls
        const renderer = this.renderer
        const scene = this.scene
        const camera = this.camera

        let elapsedTime = 0
        let lastTime = performance.now();

        const loop = (currentTime) => {
            const delta = clock.getDelta();
            elapsedTime += delta;

            // if (currentTime - lastTime > 1000 / 40) {

            // this.updateLightsPosition();
            controls.update();

            if (IS_DEBUG_MENU) {
                this.debugMenu.update();
            }

            // console.log(camera.position)

            renderer.render(scene, camera);

            if (elapsedTime > 0.1) {
                this.updateImage()
                elapsedTime = 0
            }

            lastTime = currentTime;
            // }

            requestAnimationFrame(loop);
        };


        loop()
    }

    drawLine(context, startX, startY, endX, endY, color = 'blue', lineWidth = 2) {
        context.beginPath();
        context.moveTo(startX, startY);
        context.lineTo(endX, endY);
        context.strokeStyle = color;
        context.lineWidth = lineWidth;
        context.stroke();
    }

    drawBox(context, x, y, width, height, color = 'blue', lineWidth = 2) {
        context.beginPath();
        context.rect(x, y, width, height);
        context.strokeStyle = color;
        context.lineWidth = lineWidth;
        context.stroke();
    }

    calculateRotatedSquareScale(aAngle) {
        const angle = Math.abs(aAngle * Math.PI / 180) % 90;

        let size = 1;
        const halfSize = size / 2;
        const vertices = [
            { x: -halfSize, y: -halfSize },
            { x: halfSize, y: -halfSize },
            { x: halfSize, y: halfSize },
            { x: -halfSize, y: halfSize }
        ];

        const rotatedVertices = vertices.map(vertex => {
            return {
                x: vertex.x * Math.cos(angle) - vertex.y * Math.sin(angle),
                y: vertex.x * Math.sin(angle) + vertex.y * Math.cos(angle)
            };
        });

        const xCoordinates = rotatedVertices.map(vertex => vertex.x);
        const minX = Math.min(...xCoordinates);
        const maxX = Math.max(...xCoordinates);

        let scale = size / Math.abs(maxX - minX);

        return scale;
    }

    initDrawingContextByPart(currentPart) {

        if (currentPart.drawingCanvas == undefined || currentPart.drawingCanvas == null) {
            currentPart.drawingCanvas = document.createElement('canvas');
            currentPart.drawingCanvas.width = SIZE_IMAGE_PIXEL;
            currentPart.drawingCanvas.height = SIZE_IMAGE_PIXEL;
        }

        if (currentPart.drawingContext == undefined || currentPart.drawingContext == null) {
            currentPart.drawingContext = currentPart.drawingCanvas.getContext('2d');
        }
    }

    updateImage() {

        if ((this.isTextureUpdating || this.newImageAdded || this.forceUpdate)) {
            try {
                for (const key in this.prints[this.activeItem.name]) {
                    const element = this.prints[this.activeItem.name][key];

                    if (element.updateNeeded === true || (this.activePart && this.activePart.name === key || !this.activePart)) {
                        let currentPart = this.activeItem.objects.find((el) => el.name === key)

                        this.initDrawingContextByPart(currentPart);

                        let drawingContext = currentPart.drawingContext;
                        let drawingCanvas = currentPart.drawingCanvas;

                        drawingContext.clearRect(0, 0, SIZE_IMAGE_PIXEL, SIZE_IMAGE_PIXEL);

                        drawingContext.fillStyle = currentPart.params.color.hex ?? currentPart.params.color;
                        drawingContext.fillRect(0, 0, SIZE_IMAGE_PIXEL, SIZE_IMAGE_PIXEL);

                        const activePrint = element.prints.find(item => item.id === this.activePrint);

                        element.prints.forEach(print => {

                            let x = print.pos.x;
                            let y = print.pos.y;

                            let scale = print.scale * print.maxScale * this.calculateRotatedSquareScale(print.rotation);

                            if (this.activePart && this.activePart.name === key && print && this.activePrint != undefined && activePrint && activePrint.id === print.id) {
                                if (this.movingData.vector) {
                                    x += ((this.movingData.vector.y) * (this.movingData.force * 3));
                                    y += ((this.movingData.vector.x * -1) * (this.movingData.force * 3));
                                }

                                print.pos = { x, y };
                                print.rotation = this.rotator.rotationangle;
                                print.scale = Math.max(0.01, Math.min(1, print.scale + this.scalingData));
                                scale = print.scale * print.maxScale * this.calculateRotatedSquareScale(print.rotation);

                            }

                            drawingContext.save();

                            let uvBounds = currentPart.params.uvBounds;
                            let widthUV = uvBounds.maxU - uvBounds.minU;
                            let heightUV = uvBounds.maxV - uvBounds.minV;
                            let minUV = widthUV < heightUV ? widthUV : heightUV;
                            let maxUV = widthUV > heightUV ? widthUV : heightUV;
                            let coefUF = maxUV;
                            let centerU = (uvBounds.maxU - uvBounds.minU) / 2.0;
                            let centerV = (uvBounds.maxV - uvBounds.minV) / 2.0;

                            let posX = x + SIZE_IMAGE_PIXEL * (centerU);
                            let posY = y + SIZE_IMAGE_PIXEL * (1 - centerV);

                            drawingContext.translate(posX, posY);
                            drawingContext.rotate((print.rotation + 90) * Math.PI / 180);
                            drawingContext.scale(-scale, scale);
                            drawingContext.drawImage(print.img, -SIZE_IMAGE_PIXEL / 2.0 * coefUF, -SIZE_IMAGE_PIXEL / 2.0 * coefUF, SIZE_IMAGE_PIXEL * coefUF, SIZE_IMAGE_PIXEL * coefUF);

                            drawingContext.restore();
                        });

                        const texture = new THREE.CanvasTexture(drawingCanvas);
                        texture.needsUpdate = true;
                        currentPart.material.map = texture;
                        currentPart.material.needsUpdate = true;

                        element.updateNeeded = false;
                    }
                }

                this.newImageAdded = false;
                this.forceUpdate = false;


            } catch (error) {
                console.error("Error imageHandler:", error);
                this.newImageAdded = false;
                this.forceUpdate = false;
            }
        }
    }


    showControls() {
        const zj = document.getElementById('zone_joystick')
        const rc = document.querySelector('.rotation-control')
        const sc = document.getElementById('scalecontroll')
        zj.style.visibility = 'visible'
        zj.style.zIndex = '888'
        rc.style.visibility = 'visible'
        rc.style.zIndex = '777'
        sc.style.visibility = 'visible'
        sc.style.zIndex = '999'
    }

    hideControls() {
        const zj = document.getElementById('zone_joystick')
        const rc = document.querySelector('.rotation-control')
        const sc = document.getElementById('scalecontroll')
        zj.style.visibility = 'hidden'
        zj.style.zIndex = '-1'
        rc.style.visibility = 'hidden'
        rc.style.zIndex = '-1'
        sc.style.visibility = 'hidden'
        sc.style.zIndex = '-1'
    }

    async forceRerender(partPar) {
        return new Promise(async (resolve, reject) => {
            this.forceUpdate = true
            this.updateImage();
            await new Promise(resolve => setTimeout(resolve, 100));
            return resolve()
        })
    }

    async forceRerenderPackage(data) {
        return new Promise(async (resolve, reject) => {
            this.activeItem.forceUpdate(data)
            await new Promise(resolve => setTimeout(resolve, 100));
            return resolve()
        })
    }

    async forceRerenderExtra(data) {
        return new Promise(async (resolve, reject) => {
            this.activeItem.forceUpdate(data)
            await new Promise(resolve => setTimeout(resolve, 100));
            return resolve()
        })
    }

    getElByPrintId(id) {
        return this.activeItem.objects.find((object) => {
            if (!object.params || !object.params.prints) {
                return false
            }

            return object.params.prints.find((print) => {
                return print.id == id
            })
        })
    }

    getElByName(name) {
        return this.activeItem.objects.find((object) => {
            return object.name == name
        })
    }

    getCurrentState() {
        let bus = {
            "name": "hoodie_regular_male",
            "data": {
                "parts": {
                },
            },
            "editor": {
                "name": "hoodie_regular_male",
                "data": {
                    "parts": {
                    },
                },
            }
        }

        if (!this.activeItem) {
            return
        }

        bus['name'] = this.activeItem.name
        bus['editor']['name'] = this.activeItem.name

        this.activeItem.objects.forEach((el) => {
            bus['data']['parts'][el.name] = {
                color: el.params.color,
            }

            bus['editor']['data']['parts'][el.name] = {
                color: el.params.color,
            }
        })

        for (const key in this.prints[this.activeItem.name]) {
            const element = this.prints[this.activeItem.name][key]

            if (element.prints.length > 0) {
                const calc = this.createPrints(element.prints, bus.name, key)

                bus['data']['parts'][key]['prints'] = calc[0]
                bus['data']['parts'][key]['sizeItem'] = calc[1]
                bus['data']['parts'][key]['sizeTable'] = calc[2]

                let editorData = JSON.parse(JSON.stringify(element.prints))

                for (let index = 0; index < editorData.length; index++) {
                    const element = editorData[index];
                    delete element.maxScale;
                    element.rotation = element.rotation + 90;
                }

                bus['editor']['data']['parts'][key]['prints'] = editorData;
            }
        }
        
        // console.log(bus)
        console.log(`@message.currentState.${JSON.stringify(bus)}`)

        // setTimeout(() => {
        //     const texture = this.activeItem.objects.find((el) => el.name == 'front').material.map

        //     const canvas = document.getElementById('debug-canvas');
        //     const context = canvas.getContext('2d');

        //     context.translate(0, canvas.height);
        //     context.scale(1, -1);

        //     context.drawImage(texture.image, 0, 0);
        // }, 1000);
    }

    createPrints(aPrints, aName, aKey) {
        let result = [];
        let currentSet = [];
        let currentSetBounds = { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity };

        let sizeItem = JSON.parse(JSON.stringify(itemsConfig?.[aName]?.['config']?.[aKey]?.["size"]));

        if (sizeItem === undefined) {
            console.error("Size configuration not found");
            return result;
        }

        let temp = sizeItem.height;

        sizeItem.height = sizeItem.width;
        sizeItem.width = temp;

        const maxDistance = Math.max(sizeItem.width, sizeItem.height);
        const maxDistanceTable = Math.min(this.tableSize.width, this.tableSize.height);
        const pxToMm = SIZE_IMAGE_PIXEL / maxDistance;

        const tableSizePX = { width: this.tableSize.width * SIZE_IMAGE_PIXEL / maxDistance, height: this.tableSize.height * SIZE_IMAGE_PIXEL / maxDistance };
        const sizeItemPX = { width: sizeItem.width * SIZE_IMAGE_PIXEL / maxDistance, height: sizeItem.height * SIZE_IMAGE_PIXEL / maxDistance };

        for (let index = 0; index < aPrints.length; index++) {
            const element = aPrints[index];

            const printSize = {
                width: SIZE_IMAGE_PIXEL * element.scale * element.maxScale ,
                height: SIZE_IMAGE_PIXEL * element.scale * element.maxScale 
            };

            let printPos = JSON.parse(JSON.stringify(element.pos));
            printPos.x = -printPos.y;
            printPos.y = element.pos.x;
            // printPos.y = -printPos.y;

            const printBounds = JSON.parse(JSON.stringify({
                minX: sizeItemPX.width / 2.0 + printPos.x - printSize.width / 2.0,
                minY: sizeItemPX.height / 2.0 + printPos.y - printSize.height / 2.0,
                maxX: sizeItemPX.width / 2.0 + printPos.x + printSize.width / 2.0,
                maxY: sizeItemPX.height / 2.0 + printPos.y + printSize.height / 2.0
            }));

            const newSetBounds = JSON.parse(JSON.stringify({
                minX: Math.min(currentSetBounds.minX, printBounds.minX),
                minY: Math.min(currentSetBounds.minY, printBounds.minY),
                maxX: Math.max(currentSetBounds.maxX, printBounds.maxX),
                maxY: Math.max(currentSetBounds.maxY, printBounds.maxY)
            }));

            if (
                (newSetBounds.maxX - newSetBounds.minX) <= tableSizePX.width &&
                (newSetBounds.maxY - newSetBounds.minY) <= tableSizePX.height
            ) {
                currentSet.push({
                    url: element.url,
                    id: element.id,
                    pos: {
                        x: sizeItemPX.width / 2.0 + printPos.x - printSize.width / 2.0,
                        y: sizeItemPX.height / 2.0 + printPos.y - printSize.height / 2.0
                    },
                    z: element.z,
                    scale: element.scale * this.calculateRotatedSquareScale(element.rotation),
                    rotation: element.rotation + 90
                });
                currentSetBounds = JSON.parse(JSON.stringify(newSetBounds));
            } else {
                if (currentSet.length > 0) {
                    result.push({
                        pos: {
                            x: Number(Number((currentSetBounds.minX) / SIZE_IMAGE_PIXEL * maxDistance).toFixed(2)),
                            y: Number(Number((currentSetBounds.minY) / SIZE_IMAGE_PIXEL * maxDistance).toFixed(2))
                        },
                        size: {
                            width: Number(Number((currentSetBounds.maxX - currentSetBounds.minX) / SIZE_IMAGE_PIXEL * maxDistance).toFixed(2)),
                            height: Number(Number((currentSetBounds.maxY - currentSetBounds.minY) / SIZE_IMAGE_PIXEL * maxDistance).toFixed(2))
                        },
                        list: currentSet
                    });
                }
                currentSet = [{
                    url: element.url,
                    id: element.id,
                    pos: {
                        x: sizeItemPX.width / 2.0 + printPos.x - printSize.width / 2.0,
                        y: sizeItemPX.height / 2.0 + printPos.y - printSize.height / 2.0
                    },
                    z: element.z,
                    scale: element.scale * this.calculateRotatedSquareScale(element.rotation),
                    rotation: element.rotation + 90
                }];
                currentSetBounds = JSON.parse(JSON.stringify(printBounds));
            }
        }

        if (currentSet.length > 0) {
            result.push({
                pos: {
                    // x: Math.round(( currentSetBounds.minX )/ SIZE_IMAGE_PIXEL/2.0 * maxDistance),
                    // y: Math.round(( currentSetBounds.minY) / SIZE_IMAGE_PIXEL/2.0 * maxDistance)
                    x: Number(Number((currentSetBounds.minX) / SIZE_IMAGE_PIXEL * maxDistance).toFixed(2)),
                    y: Number(Number((currentSetBounds.minY) / SIZE_IMAGE_PIXEL * maxDistance).toFixed(2))
                },
                size: {
                    width: Number(Number((currentSetBounds.maxX - currentSetBounds.minX) / SIZE_IMAGE_PIXEL * maxDistance).toFixed(2)),
                    height: Number(Number((currentSetBounds.maxY - currentSetBounds.minY) / SIZE_IMAGE_PIXEL * maxDistance).toFixed(2))
                },
                list: currentSet
            });
        }

        for (let i = 0; i < result.length; i++) {
            let item = result[i];
            let posPx = { x: item.pos.x * SIZE_IMAGE_PIXEL / maxDistance, y: item.pos.y * SIZE_IMAGE_PIXEL / maxDistance };
            for (let j = 0; j < item.list.length; j++) {
                let print = item.list[j];
                print.pos.x = Number(Number((print.pos.x - posPx.x)/SIZE_IMAGE_PIXEL * maxDistance).toFixed(2));
                print.pos.y = Number(Number((print.pos.y - posPx.y)/SIZE_IMAGE_PIXEL * maxDistance).toFixed(2));
                // item.list[j] = print;
            }
        }

        return [result, sizeItem,this.tableSize];
    }


    checkCollision(aPrints, aPosPrint, aSizePrint) {
        var width = 0;
        var height = 0;
        var isCollision = false;
        for (let index2 = 0; index2 < aPrints.length; index2++) {
            const element3 = aPrints[index2];

            if (element3 && element3.posPrint) {
                const e3x = element3.posPrint.x;
                const e3y = element3.posPrint.y;
                const e3w = element3.sizePrint.width;
                const e3h = element3.sizePrint.height;

                const px = aPosPrint.x;
                const py = aPosPrint.y;
                const pw = aSizePrint.width;
                const ph = aSizePrint.height;

                if (px < e3x + e3w && px + pw > e3x &&
                    py < e3y + e3h && py + ph > e3y) {

                    isCollision = true;
                    width = e3w;
                    height = e3h;
                    break;
                }
            }
        }

        return { isCollision: isCollision, width: width, height: height };
    }
}

/// ---------------------------------------------------------------
class ScaleController {
    constructor(joystick, onChange) {
        this.joystick = joystick;
        this.scale = 0;
        this.init();
        this.onChange = onChange;
        this.intervalId = null;
        this.isDragging = false;
        this.startY = 0;
    }

    init() {
        // this.joystick.addEventListener('mousedown', this.onMouseDown.bind(this), { passive: false });
        this.joystick.addEventListener('touchstart', this.onTouchStart.bind(this), { passive: false });
        // document.addEventListener('mouseup', this.onMouseUp.bind(this), { passive: false });
        document.addEventListener('touchend', this.onTouchEnd.bind(this), { passive: false });
        // document.addEventListener('mousemove', this.onMouseMove.bind(this), { passive: false });
        document.addEventListener('touchmove', this.onTouchMove.bind(this), { passive: false });
    }

    getJoystickCenterY() {
        const rect = this.joystick.getBoundingClientRect();
        return rect.top + rect.height / 2;
    }

    startInterval() {
        if (this.intervalId) return;
        this.intervalId = setInterval(() => {
            this.onChange(this.scale);
        }, 50);
    }

    clearInterval() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    onMouseDown(event) {
        this.isDragging = true;
        this.startY = this.getJoystickCenterY();
        this.startInterval();
        event.preventDefault();
    }

    onMouseUp(event) {
        this.isDragging = false;
        this.scale = 0;
        this.onChange(this.scale);
        this.clearInterval();
        event.preventDefault();
    }

    onMouseMove(event) {
        if (!this.isDragging) return;

        const deltaY = this.startY - event.clientY;
        this.updateScale(deltaY);

        event.preventDefault();
    }

    onTouchStart(event) {
        this.isDragging = true;
        this.startY = this.getJoystickCenterY();
        this.startInterval();
        event.preventDefault();
    }

    onTouchEnd(event) {
        this.isDragging = false;
        this.scale = 0;
        this.onChange(this.scale);
        this.clearInterval();
        event.preventDefault();
    }

    onTouchMove(event) {
        if (!this.isDragging) return;

        const deltaY = this.startY - event.touches[0].clientY;
        this.updateScale(deltaY);

        event.preventDefault();
    }

    updateScale(deltaY) {
        const coef = 150;
        let normalizedDeltaY = deltaY;

        if (normalizedDeltaY < -coef) normalizedDeltaY = -coef;
        if (normalizedDeltaY > coef) normalizedDeltaY = coef;

        this.scale = (normalizedDeltaY / coef) * 0.07;
        //this.onChange(this.scale);
    }
}


class RotationControl {
    constructor(element, onRotate) {
        this._element = element;
        this._indicator = element.querySelector('.circle-indicator');
        this._valueContainer = element.querySelector('.value');
        this._currentRotation = 0;
        this._controls = element.querySelector('.controls');
        this.onRotate = onRotate
        this.rotationangle = 0

        this._initialize();
    }

    _initialize() {
        this._updateRotation();
        this._bindEventListeners();
        this._setCircleRotation();
    }

    _bindEventListeners() {
        let _self = this;

        Draggable.create(this._indicator, {
            type: 'rotation',
            onDrag: function () {
                let rotation = parseInt(this.rotation % 360, 10);
                _self._currentRotation = (rotation < 0) ? rotation + 360 : rotation;
                _self._updateRotation();
                gsap.set(_self._indicator, { rotation: _self._currentRotation });
            }
        });
    }

    _updateRotation() {
        this.rotationangle = this._currentRotation * -1
        this.onRotate()
    }

    _rotateByAmount(amount) {
        this._currentRotation = this._currentRotation + parseInt(amount);

        let rotation = parseInt(this._currentRotation % 360, 10);
        this._currentRotation = (rotation < 0) ? rotation + 360 : rotation;

        this._updateRotation();
        this._setCircleRotation();
    }

    _setCircleRotation() {
        gsap.to(this._indicator, .5, {
            rotation: this._currentRotation,
            // ease: Expo.easeOut
        });
    }

    _reset() {
        this._currentRotation = 0;
        this._updateRotation();
        this._setCircleRotation();
    }
}

function generateUUID() {
    let uuid = '';
    const chars = '0123456789abcdef';

    for (let i = 0; i < 32; i++) {
        const index = Math.floor(Math.random() * 16);
        uuid += chars[index];
        if (i === 7 || i === 11 || i === 15 || i === 19) {
            uuid += '-';
        }
    }

    return uuid;
}

export default Editor
